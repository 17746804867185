import PropTypes from 'prop-types';
import Head from 'next/head';
import { getAuthors, getDisplayFormat, getTags } from './GoogleDataLayerCorusUtils';

// TODO: video page is TBD

/**
 * @summary Render the `<head>` script for the Corus data layer SEO JSON.
 * @param {object} props
 * @param {string | Array<{ title?: string }>} [props.author] Author or list of authors
 * @param {string | Array<{ title?: string }>} [props.authorName] Author or list of authors
 * @param {string} [props.category] Category
 * @param {string | number} [props.id] ID of the page or post.
 * @param {string | boolean} [props.isLanding] Is this a landing page?
 * @param {string} [props.pageTitle] Page title
 * @param {string} [props.profile] Profile name
 * @param {string} [props.publicationDate] Publication date
 * @param {string} [props.showName] Show name
 * @param {string | Array<string> | Array<{ name: string }>} [props.tags] Tag or list of tags
 * @param {string} [props.tagType] The type of the tag
 * @param {string} [props.type] The type of the page
 * @param {string} [props.section1] Stringified data for the first section on this page
 * @param {string} [props.section2] Stringified data for the second section on this page
 * @returns {JSX.Element}
 */
export default function GoogleDataLayerCorus({
	author,
	authorName,
	category,
	id,
	isLanding,
	pageTitle,
	profile,
	publicationDate,
	showName,
	tags,
	tagType,
	type,
	section1,
	section2,
}) {
	const authorData = author || 'Food Network Canada';
	const authorNameData = authorName || 'Food Network Canada';
	const categoryData = category || '';
	const idData = id || '';
	const isLandingData = isLanding || false;
	const pageTitleData = pageTitle || '';
	const profileData = profile || '';
	const publicationDateData = publicationDate || '';
	const showNameData = showName || '';
	const tagsData = tags || [];
	const tagTypeData = tagType || '';
	const typeData = type || '';
	const section1Data = section1 || '';
	const section2Data = section2 || '';

	const dataLayerCorus = {
		content: {
			ID: `${idData}`,
			author: getAuthors(authorData),
			authorName: getAuthors(authorNameData),
			category: `${categoryData}`,
			displayFormat: getDisplayFormat(),
			isLanding: `${isLandingData}`,
			pageTitle: `${pageTitleData}`,
			profile: `${profileData}`,
			publicationDate: `${publicationDateData}`,
			showName: `${showNameData}`, // @TODO: will need to add value for videos in the future.
			tags: getTags(tagsData),
			tagType: `${tagTypeData}`,
			type: `${typeData}`,
		},
		site: {
			language: 'en-CA',
			origin: 'foodnetwork.ca',
			section1: `${section1Data}`,
			section2: `${section2Data}`,
			section3: '',
			sitename: 'Food Network',
		},
	};

	return (
		<>
			<Head>
				<script
					id="analytics-js"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: `
							window.dataLayerCorus = ${JSON.stringify(dataLayerCorus)}
						`,
					}}
				/>
			</Head>
		</>
	);
}

GoogleDataLayerCorus.propTypes = {
	author: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
	]),
	authorName: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
	]),
	category: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLanding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	pageTitle: PropTypes.string,
	profile: PropTypes.string,
	publicationDate: PropTypes.string,
	showName: PropTypes.string,
	tags: PropTypes.array,
	tagType: PropTypes.string,
	type: PropTypes.string,
	section1: PropTypes.string,
	section2: PropTypes.string,
};

GoogleDataLayerCorus.defaultProps = {
	author: 'Food Network Canada',
	authorName: 'Food Network Canada',
	category: '',
	id: '',
	isLanding: false,
	pageTitle: '',
	profile: '',
	publicationDate: '',
	showName: '',
	tags: [],
	tagType: '',
	type: '',
	section1: '',
	section2: '',
};
