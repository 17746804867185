/**
 * @summary Get authors for the SEO JSON.
 * @param {string | Array<{ title?: string }>} creators Incoming list of creators to convert to JSON.
 * @returns {string | Array<string>}
 */
export const getAuthors = (creators) => {
	/** @type {Array<string>} */
	const result = [];

	if (!creators) return [];

	if (Array.isArray(creators)) {
		// eslint-disable-next-line array-callback-return
		creators.map((creator) => {
			result.push(creator?.title ?? 'Food Network Canada');
		});
	} else {
		result.push(creators);
	}

	if (result.length === 1) {
		return result[0];
	}
	return result;
};

/**
 * @summary Using the window's width, determine the device format.
 * @returns {(string | 'mobile' | 'tablet' | 'desktop')}
 */
export const getDisplayFormat = () => {
	let displayFormat = '';
	if (typeof window !== 'undefined') {
		const width = window.innerWidth;

		if (width <= 767) {
			displayFormat = 'mobile';
		}

		if (width >= 768 && width <= 1365) {
			displayFormat = 'tablet';
		}

		if (width >= 1366) {
			displayFormat = 'desktop';
		}
	}
	return displayFormat;
};

/**
 * @summary Get list of tags for SEO JSON.
 * @param {string | Array<string> | Array<{ name: string }>} labels Incoming label(s),
 * @returns {Array<string>}
 */
export const getTags = (labels) => {
	/** @type {Array<string>} */
	const tags = [];

	if (!labels) return [];

	if (typeof labels === 'string') {
		return [labels];
	}

	if (Array.isArray(labels)) {
		// eslint-disable-next-line array-callback-return
		labels.map((label) => {
			if (label.name) {
				tags.push(label.name.toLowerCase());
			} else {
				tags.push(label.toLowerCase());
			}
		});
	} else {
		// eslint-disable-next-line array-callback-return
		Object.keys(labels).map((label) => {
			tags.push(label.toLowerCase());
		});
	}

	return tags;
};
